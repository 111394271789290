<template>
  <div id="recording">
    <div id="select">
      <!-- <div class="topbox">
        <div class="items">
          <h4>总收入</h4>
          <h3 v-if="$store.state.userinfo">
            ¥{{ $store.state.userinfo.allzhuan }}
          </h3>
        </div>
        <div class="items">
          <h4>可提现</h4>
          <h3 v-if="$store.state.userinfo">
            ¥{{ $store.state.userinfo.zhuanqian }}
          </h3>
        </div>
        <div class="items">
          <h4>已提现</h4>
          <h3 v-if="$store.state.userinfo">
            ¥{{ $store.state.userinfo.yizhuan }}
          </h3>
        </div>
      </div> -->
      <div class="content">
        <h4>邀请明细
        <el-button type="primary" @click="close" size='medium'>立即邀请</el-button>
        </h4>
        <div
         
          class="money-list"
          v-loading="loading"
        >
          <div class="items" v-for="(item, index) in list" :key="index">
            <div class="left">
              <h6>
                {{ item.title }}   &nbsp;
                <el-tag size="mini" :type='item.type=="试用"?"success":""'>{{
                  item.type
                }}</el-tag>
              </h6>
               <p>手机号:{{ item.mobile?item.mobile:'***' }}</p>
              <p>{{ item.addtime }}</p>
            </div>
            <div class="right active" >
               <el-button type="primary" size='small' @click="synchronize(item.id)">云同步</el-button>
            </div>
          </div>
            <el-button
          round
          @click="load"
          :icon="loading?'el-icon-loading':''"
          :disabled='loading'
          size="small"
          class="more btn"
          type="primary"
          >查看更多</el-button
        >
        </div>
       
      </div>
    </div>
      <div id="alert" v-show='share'>
        

        <div class="contents">
          <h6 class="tit">邀请好友</h6>
          <div class="bindmobile">
              <el-input
              v-model="shareUrl"   
              placeholder="邀请链接"
              class="input"
              :disabled='true'
              ref='share'
            ></el-input>
             <el-button type="primary"
              v-clipboard:copy="shareUrl"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
             >复制链接</el-button>
          </div>
          <div class="qrcode" ref="qrCodeUrl"></div>
          <div class="choosebtn">
            <el-button type="primary" @click="close">确认</el-button>
            <el-button @click="close">取消</el-button>
          </div>
        </div>

        
      </div>
  </div>
</template>
<style lang="scss">
    h6{
        margin-bottom: 6px;
    }
     .tixian{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px;
   
  }
  .qrcode {
  margin: 0 auto;
  margin-bottom: 20px;

  canvas,
  img {
    display: block;
    margin: 0 auto;
  }
}
  h4{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .btn{
    display: block;
    margin: 20px auto;
  }
  .bindmobile{
      width: 100%;
  }
    @media screen and (max-width: 750px){
        #recording .content .money-list .items .right.active{
            font-size: 12px;
        }
    }
</style>
<script>
import QRCode from "qrcodejs2";
export default {
  name: "invite",
  data() {
    return {
      page: 1,
      list: [],
      loading: false,
      shareUrl:'',
      share:false,
      code:false
    };
  },
  methods: {
      close(){
          this.share=!this.share
      },
     load() {
      this.page = this.page + 1;
      this.getList();
    },
     creatQrCode() {
      const that = this;

      if (!this.code) {
        that.$nextTick(function() {
          var qrcode = new QRCode(that.$refs.qrCodeUrl, {
            text: that.shareUrl,
            width: 140,
            height: 140,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H
          });

          console.log(qrcode);
          that.code = true;
        });
      }
    },
    synchronize(id){
      this.axios.get(this.api.tongbu,{
        params:{
          toshopid:id
        }
      })
      .then((res)=>{
        if(res.code==1){
           this.$message({
                message:res.msg,
                type: "success"
              });
        }else{
           this.$message({
                message:res.msg,
                type: "warning"
              });
        }
      })
    },
    getList() {
      this.loading = true;
      this.axios
        .get(this.api.invite, {
          params: {
            page: this.page
          }
        })
        .then(res => {
          this.loading = false;
          if (res.data != "") {
            res.data.forEach(item => {
              item.addtime = this.getDate(item.addtime);
            });
            this.list = [...this.list, ...res.data];
          } else {
            if (this.page != 2) {
              this.$message({
                message: "暂无更多",
                type: "warning"
              });
            }
          }
        });
    },
    getDate(time) {
      var date = new Date(Number(time * 1000));
      let Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ",
        h = date.getHours() + ":",
        m = date.getMinutes();
      // s = date.getSeconds();
      return Y + M + D + h + m;
    },
    // 复制成功
    onCopy(){
         this.$message({
          message: '已复制到剪贴板',
          type: 'success'
        });
    },
    //复制失败的回调
    onError(){
        this.$message({
          message: '复制失败',
          type: 'warning'
        });
    }
  },
  created() {
    this.getList();
  
    this.shareUrl= window.location.protocol+'//' + window.location.host+this.api.share+'&f='+this.$store.state.userinfo.id;
    this.creatQrCode()
  }
};
</script>