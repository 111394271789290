<template>
  <div id="recording">
    <div id="select">
      <div class="topbox">
        <div class="items">
          <h4>总收入</h4>
          <h3 v-if="$store.state.userinfo">
            ¥{{ $store.state.userinfo.allzhuan }}
          </h3>
        </div>
        <div class="items">
          <h4>可提现 <p>(提现费率:<span>{{$store.state.userinfo.taocan.tixianfee*100}}%</span>)</p></h4>
          <h3 v-if="$store.state.userinfo">
            ¥{{ $store.state.userinfo.zhuanqian }}
          </h3>
          <el-button type="danger" size="medium" @click="open">立即提现</el-button>
        </div>
        <div class="items">
          <h4>已提现</h4>
          <h3 v-if="$store.state.userinfo">
            ¥{{ $store.state.userinfo.yizhuan }}
          </h3>
        </div>
      </div>
      <div class="content">
        <h4>财务记录</h4>
        <div
          v-if="$store.state.isMobile"
          class="money-list"
          v-loading="loading"
        >
          <div class="items" v-for="(item, index) in list" :key="index">
            <div class="left">
              <h6>{{ item.title }}</h6>
              <p>{{ item.addtime }}</p>
            </div>
            <div class="right " :class="item.type == '收款' ? 'active' : ''">
              <span v-if="item.type == '收款'">+</span>
              <span v-else>-</span>
              {{ item.fee }}
            </div>
          </div>
        </div>

        <el-button
          round
          @click="load"
          v-if="$store.state.isMobile"
          size="small"
          class="more"
          type="primary"
          >查看更多</el-button
        >

        <div
          v-if="!$store.state.isMobile"
          class="money-list"
          v-loading="loading"
          v-infinite-scroll="load"
        >
          <div class="items" v-for="(item, index) in list" :key="index">
            <div class="left">
              <h6>{{ item.title }}</h6>
              <p>{{ item.addtime }}</p>
            </div>
            <div class="right " :class="item.type == '收款' ? 'active' : ''">
              <span v-if="item.type == '收款'">+</span>
              <span v-else>-</span>
              {{ item.fee }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      title="提现"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :size='$store.state.isMobile?"100%":"40%"'
    >
       <div class="tixian">
          <el-input v-model="input" placeholder="请输入提现金额"></el-input> 
          <el-button type="primary" class="btn" @click="addmoney">立即提现</el-button>
       </div>
    </el-drawer>
  </div>
</template>
<style lang="scss">
  .tixian{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px;
   
  }
  .btn{
    display: block;
    margin: 20px auto;
  }
  #order .topbox .items h4, #detail .topbox .items h4, #recording .topbox .items h4{
    justify-content: flex-start;
    flex-wrap: nowrap;
    display: flex;

  }
  #order .topbox .items h4, #detail .topbox .items h4, #recording .topbox .items h4 p{
      font-size: 14px;
      width: auto;
      margin-top: 6px;
      margin-left: 4px;
      color:#656565;
  } 
</style>
<script>
export default {
  name: "recording",
  data() {
    return {
      page: 1,
      list: [],
      loading: false,
      drawer: false,
      direction: "rtl",
      input:"",
    };
  },
  methods: {
     handleClose(done) {
       done();
      },
      addmoney(){
        if(this.input!=''){
          this.axios.get(this.api.moneyadd,{
            params:{
              cash:this.input
            }
          })
          .then((res)=>{
            this.getUserinfo()
             if(res.code!=1){
                this.$message({
                message: res.msg,
                type: "warning"
              });
             }else{
               this.$message({
                message: res.msg,
                type: 'success'
              });
             }
          })
        }else{
           this.$message({
                message: "请输入提现金额",
                type: "warning"
              });
        }
      },

      open(){
          this.drawer=true
      },
    load() {
      this.page = this.page + 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.axios
        .get(this.api.moneylist, {
          params: {
            page: this.page
          }
        })
        .then(res => {
          this.loading = false;
          if (res.data != "") {
            res.data.forEach(item => {
              item.addtime = this.getDate(item.addtime);
            });
            this.list = [...this.list, ...res.data];
          } else {
             if(this.page!=2){
               this.$message({
                message: "暂无更多",
                type: "warning"
              });
             }
          }
        });
    },
    getDate(time) {
      var date = new Date(Number(time * 1000));
      let Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ",
        h = date.getHours() + ":",
        m = date.getMinutes();
      // s = date.getSeconds();
      return Y + M + D + h + m;
    },
     // 个人信息
      getUserinfo() {
      this.axios.get(this.api.userinfo).then(res => {
        if(res.code=="-100"){
          window.location.href=this.api.loginout
        }else{
          this.$store.dispatch("userinfo", res);
        }
        
      });
    },
  },

  created() {
    this.getList();
  }
};
</script>